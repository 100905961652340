var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "SKILL_BADGE_GROUP_MODAL" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            title: "Edit Assigned Skill",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hide: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t("staff.field.skills"))),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-action",
                  on: { click: _vm.skillSelectorToggle },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              ),
              _c("BadgeGroup", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c("Badge", {
                          key: index,
                          attrs: {
                            text: item.name + " (" + item.level + ")",
                            variant: "primary",
                            pillable: !!item.pillable,
                          },
                          on: {
                            badgeRemove: function ($event) {
                              return _vm.skillBadgeRemove(index)
                            },
                            badgeClick: function ($event) {
                              return _vm.skillBadgeClick(item.uuId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.skills,
                  callback: function ($$v) {
                    _vm.skills = $$v
                  },
                  expression: "skills",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.skillSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.skillSelectorShow,
              entityService: _vm.skillUtil,
              entity: "SKILL",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.skillSelectorShow = $event
              },
              cancel: _vm.skillSelectorCancel,
              ok: _vm.skillSelectorOk,
            },
          })
        : _vm._e(),
      _c("SkillLevelModal", {
        attrs: {
          show: _vm.skillLevelEditShow,
          uuId: _vm.skillLevelEdit.uuId,
          name: _vm.skillLevelEdit.name,
          level: _vm.skillLevelEdit.level,
          cData: _vm.skillLevelEdit,
          edgeName: _vm.edgeName,
        },
        on: {
          "update:show": function ($event) {
            _vm.skillLevelEditShow = $event
          },
          ok: _vm.skillLevelOk,
        },
      }),
      _vm._l(_vm.toConfirmSkills, function (item, index) {
        return [
          _c("SkillLevelModal", {
            key: "skill-" + index,
            attrs: {
              show: item.show,
              uuId: item.uuId,
              name: item.name,
              level: item.level,
              cData: item,
              edgeName: _vm.edgeName,
              canApplyAll: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmSkillOk,
              cancel: _vm.toConfirmSkillCancel,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }